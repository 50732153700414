<script>
import BVForm from '@/components/BVForm/BVForm'
import ItemList from '@/components/ItemList/ItemList'
import BVListForm from '@/components/BVForm/BVListForm.vue'
import Swal from 'sweetalert2'

export default {
    name: 'FormularioDePostulacion',
    components: {
        BVForm,
        ItemList,
        BVListForm
    },
    props: {
        vacanteId: {
            type: String,
            required: true
        },
        aspiranteId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isDataWorkspace: false,
            vacante: null,
            isPostulacionDeTalentoInterno: false,
            seccionesDelFormulario: [
                { name: "datosPersonales", text: "Datos Personales" },
                { name: "educacionFormal", text: "Educación Formal" },
                { name: "educacionNoFormal", text: "Educación No Formal" },
                { name: "experienciaProfesional", text: "Experiencia Profesional" },
                { name: "informacionFamiliar", text: "Información Familiar" },
                { name: "referenciasPersonales", text: "Referencias Personales" },
            ],
            seccionSeleccionada: "Datos Personales",

            datosPersonales: null,
            educacionFormal: null,
            educacionNoFormal: null,
            experienciaProfesional: null,
            informacionFamiliar: null,
            referenciasPersonales: null,

            hojaDeVida: null,
            timeOut: null
        }
    },
    async mounted() {
        this.getVacante()
        this.getInfoAspirante()
    },
    methods: {
        async getInfoAspirante() {
            this.datosPersonales = JSON.parse(JSON.stringify(await this.$store.dispatch('getNuevaPostulacionDatosPersonalesRestringido')))
            this.getDatosPersonales()

            this.educacionFormal = JSON.parse(JSON.stringify(await this.$store.dispatch('getNuevaPostulacionEducacionFormal')))
            this.educacionNoFormal = JSON.parse(JSON.stringify(await this.$store.dispatch('getNuevaPostulacionEducacionNoFormal')))
            this.experienciaProfesional = JSON.parse(JSON.stringify(await this.$store.dispatch('getNuevaPostulacionExperienciaProfesional')))
            this.informacionFamiliar = JSON.parse(JSON.stringify(await this.$store.dispatch('getNuevaPostulacionInformacionFamiliar')))
            this.referenciasPersonales = JSON.parse(JSON.stringify(await this.$store.dispatch('getNuevaPostulacionReferenciasPersonales')))


            // todo: quitar el timeout.
            this.timeOut = setTimeout(() => {
                this.isDataWorkspace = true
            }, 10000);
        },
        async getDatosPersonales() {

            const response = await this.$store.getters.fetchGet({ path: `Postulacion/hojaDeVida/${this.aspiranteId}` })
            const data = await response.json()

            if( data.postulante.personaId != null )
            {
                this.isPostulacionDeTalentoInterno = true
            }

            this.datosPersonales.forEach(item => {
                if (item.type == 'date') {
                    data.postulante[item.name] = data.postulante[item.name].split('T')[0]
                }

                item.value = data.postulante[item.name]

                if (item.name == 'paisId') {
                    item.options = this.$store.getters.getSourceData('paises')
                }
                else if (item.name == 'departamentoId') {
                    item.options = this.getDepartamentosByPaisId(data.postulante.paisId)
                }
                else if (item.name == 'municipioId') {
                    item.options = this.getMunicipiosByDepartamentoId(data.postulante.departamentoId)
                }
            });

            this.datosPersonales = [...this.datosPersonales]

        },
        async setDataList(self) {

            await this.getHojaDeVida()

            let data

            if (self.title == this.seccionesDelFormulario[1].text) {
                data = this.hojaDeVida.educacionFormal
            }
            else if (self.title == this.seccionesDelFormulario[2].text) {
                data = this.hojaDeVida.educacionNoFormal
            }
            else if (self.title == this.seccionesDelFormulario[3].text) {
                data = this.hojaDeVida.experienciaLaboral
            }
            else if (self.title == this.seccionesDelFormulario[4].text) {
                data = this.hojaDeVida.informacionFamiliar
            }
            else if (self.title == this.seccionesDelFormulario[5].text) {
                data = this.hojaDeVida.referenciaPersonales
            }


            for (let i = 0; i < data.length - self.oneDefault; i++) {
                self.pushNewForm()
            }

            for (let i = 0; i < data.length; i++) {
                self.forms[i].forEach(item => {
                    if (item.type == 'date') {
                        data[i][item.name] = data[i][item.name].split('T')[0]
                    }

                    item.value = data[i][item.name]
                    if (item.name == 'paisId') {
                        item.options = this.$store.getters.getSourceData('paises')
                    }
                    else if (item.name == 'departamentoId') {
                        item.options = this.getDepartamentosByPaisId(data[i].paisId)
                    }
                    else if (item.name == 'municipioId') {
                        item.options = this.getMunicipiosByDepartamentoId(data[i].departamentoId)
                    }
                })
            }


        },
        async getVacante() {
            const response = await this.$store.getters.fetchGet({ path: `vacante/${this.vacanteId}` })
            this.vacante = await response.json()
        },
        async getHojaDeVida() {
            if (this.hojaDeVida == null) {
                const response = await this.$store.getters.fetchGet({ path: `Postulacion/hojaDeVida/${this.aspiranteId}` })
                this.hojaDeVida = await response.json()
            }
        },
        getDepartamentosByPaisId(paisId) {
            return this.$store.getters.getSourceData('departamentos').filter(item => item.paisId == paisId)
        },
        getMunicipiosByDepartamentoId(departamentoId) {
            return this.$store.getters.getSourceData('municipios').filter(item => item.departamentoPaisId == departamentoId)
        },
        watcherFormularioPostulacion({ name, value, idx }) {
            if (!this.isDataWorkspace) return

            if (name == 'paisId') {
                this.datosPersonales[idx + 1].value = null
                this.datosPersonales[idx + 2].value = null
                this.datosPersonales[idx + 1].options = this.getDepartamentosByPaisId(value)
            }
            else if (name == 'departamentoId') {
                this.datosPersonales[idx + 1].value = null
                this.datosPersonales[idx + 1].options = this.getMunicipiosByDepartamentoId(value)
            }

        },
        watcherList({ name, value, idx, indexList, forms }) {

            if (!this.isDataWorkspace) return

            let form = forms[indexList]

            if (name == 'paisId') {
                form[idx + 1].value = null
                form[idx + 2].value = null
                form[idx + 1].options = this.getDepartamentosByPaisId(value)
            }

            else if (name == 'departamentoId') {
                form[idx + 1].value = null
                form[idx + 1].options = this.getMunicipiosByDepartamentoId(value)
            }
        },
        async updateFormularioDePostulacion(self) {

            self.loader = true

            this.$store.getters.fetchPut({path: `Postulacion/DatosPersonales/${this.aspiranteId}`, data: self.data})
            .then( response => {
                if( response.ok ) {
                    return response.json()
                }
                throw new Error('Error al actualizar los datos personales')
            })
            .then( () => {
                Swal.fire({
                    icon: 'success',
                    title: 'Guardado',
                    text: 'Se ha guardado correctamente',
                    showConfirmButton: true
                })
            })
            .catch( error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error.message,
                    showConfirmButton: true
                })
            })
            .finally( () => {
                self.loader = false
            })

        },

        saveListForm(self) {
            let numeroDocumento = this.datosPersonales.find(item => item.name == 'numeroDocumento').value

            self.data.forEach(element => {
                element.PostulacionNumeroDocumento = numeroDocumento
            })

            let path = ''
            if (self.title == this.seccionesDelFormulario[1].text) {
                path = `Postulacion/EducacionFormal/multiple/${numeroDocumento}`
            }
            else if (self.title == this.seccionesDelFormulario[2].text) {
                path = `Postulacion/EducacionNoFormal/multiple/${numeroDocumento}`
            }
            else if (self.title == this.seccionesDelFormulario[3].text) {
                path = `Postulacion/ExperienciaProfesional/multiple/${numeroDocumento}`
            }
            else if (self.title == this.seccionesDelFormulario[4].text) {
                path = `Postulacion/InformacionFamiliar/multiple/${numeroDocumento}`
            }
            else if (self.title == this.seccionesDelFormulario[5].text) {
                path = `Postulacion/ReferenciasPersonales/multiple/${numeroDocumento}`
            }
            else {
                return
            }

            self.loader = true
            this.$store.getters.fetchPost({ path, data: self.data })
                .then(response => {
                    if (response.ok) {
                        return response.json()
                    }
                    throw new Error('Error al guardar la educación formal')
                })
                .then( () => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Guardado',
                        text: 'Se ha guardado correctamente',
                        showConfirmButton: true
                    })
                })
                .catch(error => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.message,
                        showConfirmButton: true
                    })
                })
                .finally(() => {
                    self.loader = false
                })
        }
    }
}

</script>


<template>
    <div class="container-fluid">
        <card class="px-3">
            <section class="mt-2 mb-4">
                <h3>
                    Información de la vacante
                </h3>
                <div class="row mt-3" v-if="vacante">
                    <div class="col">
                        <ItemList title="Nombre de la empresa" :value="vacante.cargo.area.empresa.nombre" />
                        <ItemList title="Nombre de la vacante" :value="vacante.cargo.nombre" />
                    </div>
                    <div class="col">
                        <ItemList title="Fecha de cierre" :value="vacante.fechaDeCierre" />
                    </div>
                </div>
            </section>

            <section class="buttons btn-group">
                <button v-for="seccion in seccionesDelFormulario" :key="seccion.name" class="btn btn-primary btn-lg"
                    :class="{ 'active': seccion.text == seccionSeleccionada }" @click="seccionSeleccionada = seccion.text">
                    <b>{{ seccion.text }}</b>
                </button>
            </section>

            <section>
                <BVForm :title="seccionSeleccionada" :schema="datosPersonales"
                    :callBackSubmit="updateFormularioDePostulacion" :observer="['paisId', 'departamentoId']"
                    v-if="seccionSeleccionada == seccionesDelFormulario[0].text" @watcher="watcherFormularioPostulacion">
                    <template #text-submit>
                        Guardar
                    </template>
                </BVForm>

                <div 
                v-if="isPostulacionDeTalentoInterno && seccionSeleccionada != seccionesDelFormulario[0].text" class="d-flex justify-content-center p-5">
                    <h4><i>Modifique y revise su {{ seccionSeleccionada}} en el perfil</i></h4>
                </div>
                <div v-else>
                    <div v-show="seccionSeleccionada == seccionesDelFormulario[1].text">
    
                        <BVListForm v-if="educacionFormal" :title="seccionesDelFormulario[1].text" :baseSchema="educacionFormal"
                            :observer="['paisId', 'departamentoId']" @watcher="watcherList" oneDefault
                            :callBackInit="setDataList" :callBackSubmit="saveListForm"></BVListForm>
    
                    </div>
    
                    <div v-show="seccionSeleccionada == seccionesDelFormulario[2].text">
                        <BVListForm v-if="educacionNoFormal" :title="seccionesDelFormulario[2].text"
                            :baseSchema="educacionNoFormal" :observer="['paisId', 'departamentoId']" @watcher="watcherList"
                            oneDefault :callBackInit="setDataList" :callBackSubmit="saveListForm"></BVListForm>
                    </div>
    
                    <div v-show="seccionSeleccionada == seccionesDelFormulario[3].text">
                        <BVListForm v-if="experienciaProfesional" :title="seccionesDelFormulario[3].text"
                            :baseSchema="experienciaProfesional" :observer="['paisId', 'departamentoId']" @watcher="watcherList"
                            oneDefault :callBackInit="setDataList" :callBackSubmit="saveListForm"></BVListForm>
                    </div>
    
                    <div v-show="seccionSeleccionada == seccionesDelFormulario[4].text">
                        <BVListForm v-if="informacionFamiliar" :title="seccionesDelFormulario[4].text"
                            :baseSchema="informacionFamiliar" :observer="['paisId', 'departamentoId']" @watcher="watcherList"
                            oneDefault :callBackInit="setDataList" :callBackSubmit="saveListForm"></BVListForm>
                    </div>
    
                    <div v-show="seccionSeleccionada == seccionesDelFormulario[5].text">
                        <BVListForm v-if="referenciasPersonales" :title="seccionesDelFormulario[5].text"
                            :baseSchema="referenciasPersonales" :observer="['paisId', 'departamentoId']" @watcher="watcherList"
                            oneDefault :callBackInit="setDataList" :callBackSubmit="saveListForm"></BVListForm>
                    </div>
                </div>


            </section>

        </card>
    </div>
</template>


<style scoped>
.buttons {
    display: flex;
    flex-wrap: wrap;
    margin: 1rem 0;
    border-radius: 3px;
}

.buttons>* {
    border-radius: 0;
}
</style>